* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.experience,
.music,
.adventure,
.resume,
.contact-me {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.experience {
  background-image: url(https://res.cloudinary.com/dgpp4gcw5/image/upload/v1738192517/img-2_qtvuld.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); 

  height: 50vh;  /* Reduce height to half (previously fullscreen) */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  text-align: center;
  
}


.music {
  background-image: url(https://res.cloudinary.com/dgpp4gcw5/image/upload/v1738192517/img-1_rkvbns.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); 

  height: 50vh;  /* Reduce height to half (previously fullscreen) */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  text-align: center;
}

.adventure {
  background-image: url(https://res.cloudinary.com/dgpp4gcw5/image/upload/v1738192517/img-6_n11rm7.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 100px;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); 

  height: 50vh;  /* Reduce height to half */
  display: flex;
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
  text-align: center;
}

.resume {
  background-image: url(assets/files/resume.pdf);
  background-position: center;
  background-size:contain;
  background-repeat: no-repeat;
  color: #322d2d;
  font-size: 100px;
}

.contact-container {
  max-width: 75%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.contact-form {
  width: 100%;
  max-width: 400px;
}

label {
  display: block;
  text-align: left;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

input[type="submit"] {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #555;
}

.coming-soon {
  text-align: center;
  margin: 0px;
  color: #ffffff;
  font-size: large;
  background-color: #c1afa5e3; /* Adjust the color as needed */
  max-width: 11120px;
  width: 100%;
  margin: 0 auto;
  padding-top: 30px; 
  padding-left: 20px;
  padding-right: 20px;
  /*border-radius: 10px; Add rounded corners if desired */
  min-height: 35vh;
}

.coming-soon p:first-child {
  margin-bottom: 10px;
  background-color: #746e6be3;
}

@media screen and (max-width: 768px) {
  .adventure, .music, .experience{
      font-size: 3rem !important;
      text-align: center !important;
      max-width: 100%;
      margin: 0 auto;
  }
}