/* Container for the careers page */
.careers-container {
    text-align: center;
    padding: 20px;
    background-color: #746e6be3;
}

/* List of career cards */
.careers-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    transform: translateY(-100px);
}

/* Individual career card */
.career-card {
    display: flex;
    align-items: center;
    background: rgba(219, 225, 228, 0.95); /* Slight transparency for readability */
    border-radius: 20px;
    border: 1px solid black;
    padding: 30px;
    width: 97%; /* Takes up more screen width */
    max-width: 1100px; /* Ensures a consistent max width */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Hover effect */
.career-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
}

/* Company logo */
.company-logo {
    height: 200px; /* Reverted to original size */
    aspect-ratio: 1 / 1;
    object-fit: fill;
    margin-right: 30px;
}

/* Job details */
.career-info {
    flex: 1;
    text-align: left;
}

.career-info h2 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 5px;
}

/* Company Name Styling (Higher Placement) */
.company-name {
    font-size: 18px;
    font-weight: bold;
    color: #444;
    margin-bottom: 4px;
    position: relative;
    top: -5px; /* Moves it slightly upwards */
}

/* Job Title Styling */
.job-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Adjust Bullet Points for Spacing */
.career-description {
    margin-top: 10px; /* Ensures spacing below job title */
    padding-left: 20px;
    list-style-type: disc; /* Standard bullet points */
}

.career-description li {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
}

.career-info p {
    margin: 5px 0;
    font-size: 16px;
    color: #555;
}

/* See more link */
.see-more {
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.see-more:hover {
    color: #0056b3;
}

@media screen and (max-width: 768px) {
    .career-card {
        flex-direction: column; /* Stack content vertically on mobile */
        width: 95%;
        text-align: center; /* Center everything */
        padding: 20px; /* Slightly smaller padding */
    }

    .career-card img {
        max-width: 200px; /* Smaller logo on mobile */
        margin-bottom: 10px; /* Space below image */
        margin-right: 0; /* Remove right margin */
    }

    .career-info {
        text-align: center;
    }

    .career-description {
        font-size: 14px; /* Smaller text to fit */
        word-wrap: break-word; /* Prevent text overflow */
        text-align: left;
    }
}
