.footer-container {
    background-color: #54595c;
    padding: 2rem 0; /* Adjust padding as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto; /* Allow dynamic height */
    width: 100%; /* Ensure it spans full width */
    position: relative; /* Avoid overlap issues */
    bottom: 0;
}

/* Ensure the footer is always at the bottom */
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#root {
    flex: 1;
}

  
.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 12px; /* Reduced margin */
    padding: 12px; /* Reduced padding */
    color: #fff;
}
  
.footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
  
.footer-subscription-heading {
    margin-bottom: 12px; /* Reduced margin */
    font-size: 20px; /* Reduced font size */
}
  
.footer-subscription-text {
    margin-bottom: 12px; /* Reduced margin */
    font-size: 16px; /* Reduced font size */
}
  
.footer-input {
    padding: 6px 10px; /* Reduced padding */
    border-radius: 2px;
    margin-right: 8px; /* Reduced margin */
    outline: none;
    border: none;
    font-size: 16px; /* Reduced font size */
    margin-bottom: 8px; /* Reduced margin */
    border: 1px solid #fff;
}
  
.footer-links {
    width: 100%;
    max-width: 800px; /* Reduced max width */
    display: flex;
    justify-content: center;
}

.footer-link-wrapper {
    display: flex;
    flex-direction: column;
}
  
.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 8px;
    text-align: left;
    width: 120px; /* Reduced width */
    box-sizing: border-box;
}
  
.footer-link-items h2 {
    margin-bottom: 8px; /* Reduced margin */
    font-size: 18px; /* Reduced font size */
}
  
.footer-link-items > h2 {
    color: #fff;
}
  
.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.25rem; /* Reduced margin */
}
  
.footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
}
  
.footer-email-form h2 {
    margin-bottom: 1rem; /* Reduced margin */
}
  
.footer-input::placeholder {
    color: #b1b1b1;
}
  
.social-icon-link {
    color: #fff;
    font-size: 20px; /* Reduced font size */
}
  
.social-media {
    max-width: 800px; /* Reduced max width */
    width: 100%;
}
  
.social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%; /* Reduced width */
    max-width: 800px; /* Reduced max width */
    margin: 20px auto 0 auto; /* Reduced margin */
}
  
.social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px; /* Reduced width */
}
  
.social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 10px; /* Reduced margin */
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem; /* Reduced font size */
    display: flex;
    align-items: center;
    margin-bottom: 8px; /* Reduced margin */
}
  
.website-rights {
    color: #fff;
    margin-bottom: 8px; /* Reduced margin */
}
  
@media screen and (max-width: 820px) {
    .footer-links {
        padding-top: 1rem; /* Reduced padding */
    }
  
    .footer-input {
        width: 100%;
    }
  
    .footer-link-wrapper {
        flex-direction: column;
    }
  
    .social-media-wrap {
        flex-direction: column;
    }
}
  
@media screen and (max-width: 768px) {
    /* Adjustments for smaller screen sizes if needed */
}

.social-icon-link.email i {
    color: white;
  }
  