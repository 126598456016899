/* Container for the gallery */
.gallery-container {
  text-align: center;
  padding: 20px;
  min-height: 35vh;
}

/* Filter buttons */
.filter-buttons {
  margin-bottom: 20px;
}

.filter-buttons button {
  border-radius: 10px;
  margin: 5px;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  background-color: rgb(219, 225, 228);
  border: 2px solid black;
}

.filter-buttons button.active {
  background-color: black;
  color: white;
}

/* Grid layout for images */
/* Container for the gallery */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Ensures responsiveness */
  gap: 10px;
  justify-content: center;
  padding: 20px;
}

/* Individual photo container */
.photo-container {
  position: relative;
  width: 100%;
  padding-top: 66%; /* Keeps images proportional */
  overflow: hidden;
}

/* Ensure all images stay consistent */
.gallery-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures proper cropping */
  transition: transform 0.3s ease-in-out;
}

/* Hover effect: dark overlay */
.photo-container:hover .gallery-image {
  filter: brightness(50%);
}

/* Hover effect: Location text */
.photo-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px; /* Increased font size */
  font-weight: bold;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); /* Soft glow effect for readability */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.photo-container:hover .photo-overlay {
  opacity: 1;
}

